// Sitewide - Global
import 'slick-carousel';
import jQuery from 'jquery';
window.jQuery = window.$ = jQuery;

const table_scroll_triggers = document.querySelectorAll('.js-table-scroll');

// Scroll table to the right or left
for (const table_scroll_trigger of table_scroll_triggers) {
  const id = table_scroll_trigger.dataset.id;
  const direction = table_scroll_trigger.dataset.direction;

  const myDivElement = document.getElementById(id);

  table_scroll_trigger.addEventListener('click', () => {
    if (direction == 'right') {
      myDivElement.scrollLeft = myDivElement.scrollWidth;
    }
    if (direction == 'left') {
      myDivElement.scrollLeft = 0;
    }
  });
}

const increasers = document.querySelectorAll('.js-increase');
// Qty increaser
for (const increaser of increasers) {
  increaser.addEventListener('click', (e) => {
    e.preventDefault();
    const key = increaser.dataset.key;
    const el = document.getElementById('updates_' + key);
    const qty = parseInt(el.value);
    el.value = qty + 1;
  });
}

const decreasers = document.querySelectorAll('.js-decrease');
// Qty decreaser
for (const decreaser of decreasers) {
  decreaser.addEventListener('click', (e) => {
    e.preventDefault();
    const key = decreaser.dataset.key;
    const el = document.getElementById('updates_' + key);
    const qty = parseInt(el.value);
    el.value = qty <= 1 ? 0 : qty - 1;
  });
}

// Return to top button
(function () {
  document.addEventListener('DOMContentLoaded', function () {
    const height = Math.ceil(document.body.scrollHeight / 3);
    const backToTop = document.querySelector('.js-back-to-top');

    if (height > 1000 && backToTop) {
      document.querySelector('.js-scroll-to-top').addEventListener('click', () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      });

      const options = {
        rootMargin: `0px 0px ${height}px 0px`,
        threshold: 0
      };

      const removeBackToTop = () => {
        if (backToTop.classList.contains('opacity-0')) {
          backToTop.classList.add('hidden');
        } else {
          backToTop.removeEventListener('transitionend', removeBackToTop);
          backToTop.classList.remove('hidden');
          backToTop.classList.remove('opacity-0');
        }
      };

      const showBackToTop = (entires) => {
        entires.forEach((entry) => {
          if (entry.isIntersecting) {
            backToTop.classList.remove('hidden');
            window.requestAnimationFrame(() => {
              backToTop.classList.remove('opacity-0');
            });
          } else {
            if (!backToTop.classList.contains('hidden')) {
              window.requestAnimationFrame(() => {
                backToTop.classList.add('opacity-0');
                backToTop.addEventListener('transitionend', removeBackToTop, { once: true });
              });
            }
          }
        });
      };

      const footerObserver = new IntersectionObserver(showBackToTop, options);
      footerObserver.observe(document.getElementById('shopify-section-footer'));
    }
  });
})();

// Megamenu Style Icons
if (document.querySelector('.js-megamenu-style-icons li')) {
  // Get all megamenu style icons
  let icons = {};
  icons.thongs = document.querySelectorAll('.js-megamenu-style-icons .thongs-icon');
  icons.panties = document.querySelectorAll('.js-megamenu-style-icons .panties-icon');
  icons.bras = document.querySelectorAll('.js-megamenu-style-icons .bras-icon');

  let megamenus = {};
  megamenus.thongs = document.querySelectorAll('.js-megamenu-style-icons-thongs');
  megamenus.panties = document.querySelectorAll('.js-megamenu-style-icons-panties');
  megamenus.bras = document.querySelectorAll('.js-megamenu-style-icons-bras');

  // Insert megamenu style icons in corresponding megamenu
  for (let icon_group in icons) {
    if (megamenus[icon_group].length > 0) {
      let template = document.createElement('template');

      icons[icon_group].forEach((e) => {
        template.content.appendChild(e);
      });

      megamenus[icon_group].forEach((e) => {
        let clone = template.content.cloneNode(true);
        e.append(clone);
      });
    }
  }
}

// Function to toggle aria-haspopup attribute
function toggleAriaHasPopup() {
  const megaMenuItems = document.querySelectorAll('.megamenu-item');

  // Adding event listeners to each item
  megaMenuItems.forEach(item => {
      item.addEventListener('focus', () => {
          item.setAttribute('aria-expanded', 'true');
      });
      item.addEventListener('blur', () => {
          item.setAttribute('aria-expanded', 'false');
      });
  });
}

// Call the function on document load or when appropriate
document.addEventListener('DOMContentLoaded', toggleAriaHasPopup);
